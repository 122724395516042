import styled, { css } from 'styled-components'

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin-left: -60px;
  margin-right: -60px;
`

export const Column = styled.div`
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 60px;

  ${props =>
    props.width === 4 &&
    css`
      @media (min-width: 960px) {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
      }
    `}

  ${props =>
    props.width === 8 &&
    css`
      @media (min-width: 960px) {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
        padding-left: 72px;
      }
    `}
`
