import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Intro from '../components/Intro'
import Title from '../components/Title'
import { Columns, Column } from '../components/Columns'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

// TODO: Create component
const TitleContainer = styled.div`
  @media (min-width: 1024px) {
    padding-left: 37.5%;
  }
`

const ImpressumPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'nav.imprint' })} />
      <Intro image={data.introImage} />
      <Content>
        <TitleContainer>
          <Title>{intl.formatMessage({ id: 'imprint.title' })}</Title>
        </TitleContainer>
        <Columns>
          <Column width={4}>
            <Fade>
              <>
                <strong>
                  {intl.formatMessage({ id: 'imprint.representative' })}
                </strong>
                <br />
                Oberhuber Jenal
                <br />
                Rechtsanwälte AG
                <br />
                Wuhrstrasse 14
                <br />
                9490 Vaduz
                <br />
                <br />
                {intl.formatMessage({ id: 'contact.phone' })} +423 237 70 80
                <br />
                <a href="mailto:info@oberhuberjenal.li">
                  info@oberhuberjenal.li
                </a>
                <br />
                <br />
                <strong>
                  {intl.formatMessage({ id: 'imprint.register' })}
                </strong>
                <br />
                FL-0002.598.516-7
                <br />
                <br />
                <strong>{intl.formatMessage({ id: 'imprint.vat' })}</strong>
                <br />
                60 773
                <br />
                <br />
                <strong>{intl.formatMessage({ id: 'imprint.text' })}</strong>
                <br />
                © Oberhuber Jenal Rechtsanwälte&nbsp;AG
                <br />
                <br />
                <strong>{intl.formatMessage({ id: 'imprint.design' })}</strong>
                <br />
                <a href="https://www.gebrauchsgraphik.net/">
                  Büro für Gebrauchsgraphik AG, Vaduz
                </a>
                <br />
                <br />
                <strong>
                  {intl.formatMessage({ id: 'imprint.development' })}
                </strong>
                <br />
                <a href="https://www.a45.li/">A45 Anstalt, Vaduz</a>
              </>
            </Fade>
          </Column>
          <Column width={8}>
            <Fade>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: 'imprint.content' }),
                }}
              />
            </Fade>
          </Column>
        </Columns>
      </Content>
    </Layout>
  )
}

export default ImpressumPage

export const query = graphql`
  query {
    introImage: file(relativePath: { eq: "anwaelte_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
